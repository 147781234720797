import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../legends/legends.css'

function Legend ({displayLegend}){

    const chooseLegend = displayLegend;
    let legend = <div><p>'No legend to show for this type.'</p></div>
    if(chooseLegend === 'Pressure'){
       legend = <div><p>'No legend to show for Pressure.'</p></div>
    }
    else if(chooseLegend === 'ALERT'){
        legend = 
        <Container fluid className="myLegend nothasLegend">
            <Row>
                <Col className="text-center legendTabs">0</Col>
                <Col className="text-center legendTabs">1</Col>
                <Col className="text-center legendTabs">2</Col>
                <Col className="text-center legendTabs">3</Col>
                <Col className="text-center legendTabs">4</Col>
                <Col className="text-center legendTabs">5</Col>
            </Row>
        </Container>
    }
    else if(chooseLegend === 'Wind Gust'){
        legend = 
        <Container fluid className="myLegend windGustLegend">
            <Row>
                <Col className="text-center legendTabs">0.1</Col>
                <Col className="text-center legendTabs">2.5</Col>
                <Col className="text-center legendTabs">7.5</Col>
                <Col className="text-center legendTabs">10</Col>
                <Col className="text-center legendTabs">12.5</Col>
                <Col className="text-center legendTabs">15</Col>
                <Col className="text-center legendTabs">17.5</Col>
                <Col className="text-center legendTabs">20</Col>
                <Col className="text-center legendTabs">25</Col>
                <Col className="text-center legendTabs">30</Col>
            </Row>
        </Container>
    }
    else if(chooseLegend === 'Wind Speed'){
        legend = 
        <Container fluid className="myLegend windSpeedLegend">
            <Row>
                <Col className="text-center legendTabs">0.3</Col>
                <Col className="text-center legendTabs">1</Col>
                <Col className="text-center legendTabs">2.5</Col>
                <Col className="text-center legendTabs">5</Col>
                <Col className="text-center legendTabs">7.5</Col>
                <Col className="text-center legendTabs">10</Col>
                <Col className="text-center legendTabs">12.5</Col>
                <Col className="text-center legendTabs">15</Col>
                <Col className="text-center legendTabs">17.5</Col>
                <Col className="text-center legendTabs">20</Col>
                <Col className="text-center legendTabs">25</Col>
                <Col className="text-center legendTabs">30</Col>
                <Col className="text-center legendTabs">40</Col>
            </Row>
        </Container>
    }
    else if(chooseLegend === 'Clouds'){
        legend = 
        <Container fluid className="myLegend cloudsLegend">
            <Row>
                <Col className="text-center legendTabs">1</Col>
                <Col className="text-center legendTabs">2</Col>
                <Col className="text-center legendTabs">3</Col>
                <Col className="text-center legendTabs">4</Col>
                <Col className="text-center legendTabs">5</Col>
                <Col className="text-center legendTabs">6</Col>
                <Col className="text-center legendTabs">7</Col>
                <Col className="text-center legendTabs">8</Col>
            </Row>
        </Container>
    }
    else if(chooseLegend === 'Radar reflectivity'){
        legend = 
        <Container fluid className="myLegend radarLegend">
            <Row>
                <Col className="text-center legendTabs">5</Col>
                <Col className="text-center legendTabs">10</Col>
                <Col className="text-center legendTabs">15</Col>
                <Col className="text-center legendTabs">20</Col>
                <Col className="text-center legendTabs">25</Col>
                <Col className="text-center legendTabs">30</Col>
                <Col className="text-center legendTabs">40</Col>
                <Col className="text-center legendTabs">45</Col>
                <Col className="text-center legendTabs">50</Col>
                <Col className="text-center legendTabs">55</Col>
                <Col className="text-center legendTabs">60</Col>
            </Row>
        </Container>
    }
    else if(chooseLegend === 'Rain'){
        legend = 
        <Container fluid className="myLegend rainLegend">
            <Row>
                <Col className="text-center legendTabs">0.3</Col>
                <Col className="text-center legendTabs">1</Col>
                <Col className="text-center legendTabs">2.5</Col>
                <Col className="text-center legendTabs">5</Col>
                <Col className="text-center legendTabs">7.5</Col>
                <Col className="text-center legendTabs">10</Col>
                <Col className="text-center legendTabs">12.5</Col>
                <Col className="text-center legendTabs">15</Col>
                <Col className="text-center legendTabs">17.5</Col>
                <Col className="text-center legendTabs">20</Col>
                <Col className="text-center legendTabs">25</Col>
            </Row>
        </Container>
    }
    else if(chooseLegend === 'Temperature'){
        legend = 
        <Container fluid className="myLegend temperatureLegend">
            <Row>
                <Col className="text-center legendTabs">-10</Col>
                <Col className="text-center legendTabs">-5</Col>
                <Col className="text-center legendTabs">0</Col>
                <Col className="text-center legendTabs">2</Col>
                <Col className="text-center legendTabs">4</Col>
                <Col className="text-center legendTabs">6</Col>
                <Col className="text-center legendTabs">8</Col>
                <Col className="text-center legendTabs">10</Col>
                <Col className="text-center legendTabs">12</Col>
                <Col className="text-center legendTabs">14</Col>
                <Col className="text-center legendTabs">16</Col>
                <Col className="text-center legendTabs">18</Col>
                <Col className="text-center legendTabs">20</Col>
            </Row>
        </Container>
    }
    else if(chooseLegend === 'Visibility'){
        legend = 
        <Container fluid className="myLegend visibilityLegend">
            <Row>
                <Col className="text-center legendTabs">100</Col>
                <Col className="text-center legendTabs">250</Col>
                <Col className="text-center legendTabs">500</Col>
                <Col className="text-center legendTabs">750</Col>
                <Col className="text-center legendTabs">1000</Col>
                <Col className="text-center legendTabs">1500</Col>
                <Col className="text-center legendTabs">2000</Col>
                <Col className="text-center legendTabs">3000</Col>
            </Row>
        </Container>
    }

    return (
        <div>{legend}</div>
            
    );
}

export default Legend;

