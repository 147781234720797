import React from 'react'
import L from 'leaflet'
import {Map, TileLayer, ImageOverlay, Popup, CircleMarker } from 'react-leaflet';
import Header from "./header";
import Legend from './legends/legend'
import SidePanel from './sidePanel';
import { Container, Row, Col, Button } from "react-bootstrap";
import moment from 'moment';
import { geolocated } from "react-geolocated";
import pmalarmLogo from './logo/pmAlarm_logo.png'
import _, { size } from 'lodash'
import { Slider, Tooltip} from '@material-ui/core';
import SliderTooltip from './SliderTooltip';
import DropdownPollutant from './DropdownPollutant';
import DropdownDomain from './DropdownDomain';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "./custom-axios/axios";
import ErrDiv from './errorMsg';
import whiteImg from '../images/white.PNG';
import DetailsForPollutantsByLocation from './DetailsForPollutantsByLocation';

let interval = null;

class Mapa extends React.Component {

     async fetchPollutantValue(pollutantType, domain, latitude, longitude) {
         await axios.get('/prediction/pollutant/' + 12 + '/domain/' + domain +'/daysplus/3/daysminus/0/location/lat/' + latitude.toFixed(4) + '/lng/' + longitude.toFixed(4) +'/')
             .then(res => {
                if(res.data.length < 74 && res.data.length > 45) {
                    let myCas = 0;
                    for(let i = 0; i < res.data.length - 1; ++i) {
                        if(res.data[i].cas === 23){
                            myCas = 0;
                            if(res.data[i+1].cas === 0){
                                continue;
                            } else {
                                const field = { 
                                    id: i + 2, 
                                    datum: undefined,
                                    cas: myCas,
                                    val: null
                                };
                                res.data.splice(i+1 , 0, field);
                                continue;
                            }
                        }
                        if(res.data[i].cas !== res.data[i+1].cas - 1){
                            const field = { 
                                id: i + 2, 
                                datum: undefined,
                                cas: myCas+1,
                                val: null
                            };
                            res.data.splice(i+1 , 0, field);
                        }
                        ++myCas;
                    }
                } else if(res.data.length < 45){
                    this.openErrDivDetails()
                }
                this.setState({
                    pollutantValues72: res.data,
                    loadingData: false,
                })
            })
        .catch((error) => {
            if(error.message==="Network Error"){
                this.setState({
                    errMSGVisible:true,
                    errorText:"Поврзувањето е неуспешно!"
                });
                this.closePopup();
                this.closeDetails();
            }
            if(error.message === "Resource not found.") {
                this.setState({
                    errMSGVisible:true,
                    errorText:"Нема податоци за избраната локација!"
                });
                this.closePopup();
                this.closeDetails();
            }
         });
    };

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.handleOnChange = this.handleOnChange.bind(this);
        this.player = this.player.bind(this);
        this.pausePlayer = this.pausePlayer.bind(this);
        this.changeHour = this.changeHour.bind(this);
        //this.closeDetails = this.closeDetails.bind(this);
        this.state = {
            bound: L.latLngBounds(L.latLng(37.18222,  11.08), L.latLng(46.97,  26.899)),
            //zoom: 8.5,
            zoom: 6,
            maxZoom: 10,
            hour: new Date().getHours(),
            started: true,
            startDate: new Date().setHours(0),
            currentDate: new Date(),
            searchId: this.props.searchId,
            searchCity: this.props.searchCity,
            popupLatLng: (this.props.match.params.lat && this.props.match.params.lng) ? {
                "lat": parseFloat(this.props.match.params.lat),
                "lng": parseFloat(this.props.match.params.lng)
            } : null,
            popupVisible: (this.props.match.params.lat && this.props.match.params.lng && !this.props.match.params.details) ? true : false,
            showLocationDetails: (this.props.match.params.lat && this.props.match.params.lng && this.props.match.params.details === 'details') ? true : false,
            selectedLocation: (this.props.match.params.lat && this.props.match.params.lng) ? {
                "latitude": parseFloat(this.props.match.params.lat),
                "longitude": parseFloat(this.props.match.params.lng)
            } : null,
            circleVisible: (this.props.match.params.lat && this.props.match.params.lng) ? true : false,
            //pollutantValue: 0,
           // pollutantValue24:[],
            //pollutantType: 1,
            urlLat: this.props.match.params.lat,
            urlLng: this.props.match.params.lng,
            loadingData: true,
            isPlaying:false,
            pollutantName:'ALERT',
            pollutantId: 1,
            pollutantValue: undefined,
            sidePanelVisible: false,
            errMSGVisible: false,
            errorText:"",
            domain: 1,
            pollutantValues72: [],
            imageBoundsFirst: 37.18222,
            imageBoundsSecond:  11.08,
            imageBoundsThird: 46.97,
            imageBoundsFourth: 26.899,
            positionCenterLat: 42.642,
            positionCenterLng: 18.94,
            imageFailedToLoad: false,
        }
    }
    
    isDetailsActive = (e) => {
        let s = '/' + e.latlng.lat.toFixed(4) + '/' + e.latlng.lng.toFixed(4);
        window.history.pushState({"html": "", "pageTitle": ""}, "", s);
        this.setState({
            selectedLocation: {"latitude": e.latlng.lat, "longitude": e.latlng.lng},
            popupLatLng: {"lat": e.latlng.lat, "lng": e.latlng.lng}
        })
        if (this.state.showLocationDetails) {
            this.openDetails(e);
            this.setState({
                popupVisible: false,
                selectedLocation: {"latitude": e.latlng.lat, "longitude": e.latlng.lng}
            })

        } else {
            this.addPopup(e);
        }

    };


    changePollutant = (pollutant) => {
        // const array = pollutantIdAndName.split(" ");
        //   this.setState({
        //     pollutantId: array[0],
        //     pollutantName: array[1]
        // });
        this.setState({
                pollutantName: pollutant
        });
    };

    changeDomain = (domainId) => {
        let europeBoundsFirst = 37.18222;
        let europeBoundsSecond = 11.08;
        let europeBoundsThird = 46.97;
        let europeBoundsFourth = 26.899;

        let macedoniaBoundsFirst = 40.30;
        let macedoniaBoundsSecond = 19.96;
        let macedoniaBoundsThird = 42.50;
        let macedoniaBoundsFourth = 23.87;
        if(domainId === "3"){
            this.setState({
                bound: L.latLngBounds(L.latLng(macedoniaBoundsFirst, macedoniaBoundsSecond), L.latLng(macedoniaBoundsThird, macedoniaBoundsFourth)),
                positionCenterLat: 41.8869,
                positionCenterLng: 21.5099, 
                domain: domainId,
                imageBoundsFirst: macedoniaBoundsFirst,
                imageBoundsSecond: macedoniaBoundsSecond,
                imageBoundsThird: macedoniaBoundsThird,
                imageBoundsFourth: macedoniaBoundsFourth,
                zoom: 8,
                maxZoom: 8
            });
        } else if(domainId === "1"){
            this.setState({
                bound: L.latLngBounds(L.latLng(europeBoundsFirst, europeBoundsSecond), L.latLng(europeBoundsThird, europeBoundsFourth)),
                domain: domainId,
                positionCenterLat: 42.642,
                positionCenterLng: 18.94,
                imageBoundsFirst: europeBoundsFirst,
                imageBoundsSecond: europeBoundsSecond,
                imageBoundsThird: europeBoundsThird,
                imageBoundsFourth: europeBoundsFourth,
                zoom: 6,
                maxZoom: 6,
            });
        }
    };


    setCurrentLocation = () => {
        let s = '/' + this.props.coords.latitude.toFixed(4) + '/' + this.props.coords.longitude.toFixed(4);
        window.history.pushState({"html": "", "pageTitle": ""}, "", s);
        this.setState({
            selectedLocation: {"latitude": this.props.coords.latitude, "longitude": this.props.coords.longitude},
            popupLatLng: {"lat": this.props.coords.latitude, "lng": this.props.coords.longitude}
        })
        if (this.state.showLocationDetails) {
            this.openDetails();
            this.setState({
                popupVisible: false,
                // selectedLocation: {"latitude": e.latlng.lat, "longitude": e.latlng.lng}
            })
        } else {
            this.addPopup();
        }
    };

    // fetchImage = (date) => {
    //     const dateF = this.dateToUrl(date);
    //     let prodOrDevUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_URL : process.env.REACT_APP_PROD_URL;
    //     // console.log(dateF);
    //     //return "http://localhost:8080/api/images/" + dateF + this.state.pollutantName;
    //     return prodOrDevUrl + "/images/type/" + this.state.pollutantName + "/domain/" + this.state.domain + "/date/" + dateF;
    // }

    dateToUrl = (date) => {
        const dateF = moment(date).subtract(2,"hours").format('YYYY-MM-DD_HH-00-00');
        return "https://aqf.finki.ukim.mk/images/"+this.state.pollutantName+"/wrfout_d0"+this.state.domain+"_" + dateF + ".png";
    }

    addPopup = () => {
        this.fetchPollutantValue(this.state.pollutantName, this.state.domain, this.state.popupLatLng.lat, this.state.popupLatLng.lng);
        this.setState({
            popupVisible: true,
            loadingData: true,
            circleVisible:true
        });
    };

    closePopup = () => {
        window.history.pushState({"html": "", "pageTitle": ""}, "", '../../');
        this.setState({
            popupVisible: false,
            circleVisible:false,
            pollutantValue: undefined,
            loadingData: true
        })
    }

    openDetails = () => {
        let s = '/' + this.state.popupLatLng.lat.toFixed(4) + '/' + this.state.popupLatLng.lng.toFixed(4) + '/details';
        window.history.pushState({"html": 0, "pageTitle": 'alert'}, "", s);
        this.setState({showLocationDetails: true, popupVisible: false, circleVisible: true})
    }


    closeDetails = () => {
        window.history.replaceState({"html": "", "pageTitle": ""}, "", '../../');
        this.setState({showLocationDetails: false, circleVisible: false})
    }

    detailsStateUpdateVisible=(lat,lng)=>{
        this.setState({
            showLocationDetails:true,
            selectedLocation: {"latitude": lat, "longitude": lng},
            popupLatLng: {"lat": lat, "lng": lng},
            circleVisible: true,
            popupVisible: false,
        })
    };
    
    //Heat map 24 hours player
    player() {
        this.setState({
            isPlaying:true
        })
        if (this.state.started) {
            interval = setInterval(this.changeHour, 1000);
        }
    }
    
    pausePlayer() {
        this.setState({
            started: true,
            isPlaying:false
        });
        clearInterval(interval);
    }

    changeHour() {

        if (this.state.hour >= 72) {
            this.setState({
                hour: 0,
                currentDate: this.state.startDate
            })
        } else {
            this.setState({
                hour: this.state.hour + 1,
                currentDate: moment(this.state.startDate).add(this.state.hour, 'hours'),
                started: false
            });
        }

    }

    //Manual hour change on player
    handleOnChange = (event, value) => {
        this.setState({
                hour: value,
                currentDate: new Date().setHours(value),
        });
    };

    handleImageLoad = () => {
        this.setState({
            imageFailedToLoad: false,
        })
    }

    handleImageError = () => {
        this.setState({
            imageFailedToLoad: true,
        })
    }

    openSidePanel=()=>{
        this.setState({sidePanelVisible:true})
    }

    closeSidePanel=()=>{
        this.setState({sidePanelVisible:false})
    };

    closeErrDiv=()=>{
        this.setState({
            errMSGVisible:false
        })
    };

    openErrDivDetails=()=>{
      this.setState({
          errMSGVisible:true,
          errorText:"Нема податоци за избраната локација!"

      });
        this.closePopup();
        this.closeDetails();
    };

    render() {

        let maxZoom = 15;
        let zoomSnapHere = 0.01;
        let opacity = 0.5;


        const position = [this.state.positionCenterLat, this.state.positionCenterLng];

        let today = new Date();
        let date = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();


        let tomorrowDate = new Date()
        tomorrowDate.setDate(today.getDate() + 1);
        let tomorrow = tomorrowDate.getDate() + '.' + (tomorrowDate.getMonth() + 1) + '.' + tomorrowDate.getFullYear();

        let dayTwoDate = new Date()
        dayTwoDate.setDate(today.getDate() + 2);
        let dayTwo = dayTwoDate.getDate() + '.' + (dayTwoDate.getMonth() + 1) + '.' + dayTwoDate.getFullYear();

        // let dayThreeDate = new Date()
        // dayThreeDate.setDate(today.getDate() + 3);
        // let dayThree = dayThreeDate.getDate() + '.' + (dayThreeDate.getMonth() + 1) + '.' + dayThreeDate.getFullYear();


        let time = today.getHours();

        const marks = [
            {
                value: 0,
                label: date,
            },
            {
                value: 24,
                label: tomorrow,
            },
            {
                value: 48,
                label: dayTwo,
            },
            // {
            //     value: 72,
            //     label: dayThree,
            // },
            {
                value: 72,
                label: '',
            }

        ];

        // if(this.state.pollutantName === 'Temperature') {
        //     opacity = 0.4;
        // }


        return (
            <Container fluid>

                {this.state.sidePanelVisible && <SidePanel sidePanelvisible={this.closeSidePanel} />}

                <Header detailsStateUpdate={this.detailsStateUpdateVisible} activateSidePanel={this.openSidePanel}/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

                {this.state.errMSGVisible && <ErrDiv errorMessage={this.state.errorText} closeErrDiv={this.closeErrDiv} />}


                <Map ref={this.mapRef} className="mapa" center={position} zoom={(this.state.zoom)} zoomControl={false}
                     maxZoom={maxZoom} minZoom={this.state.zoom} trackResize={false}  setZoom={this.state.zoom}
                     maxBounds={this.state.bound} fitBounds={false} onClick={this.isDetailsActive} closePopupOnClick >

                    <ImageOverlay url={whiteImg} bounds={[[this.state.imageBoundsFirst, -165.6], [180, this.state.imageBoundsSecond]]}
                                  opacity={opacity} className="imageOverlay" />

                    <ImageOverlay url={whiteImg} bounds={[[-51.6, -165.6], [this.state.imageBoundsFirst, 180]]}
                                  opacity={opacity} className="imageOverlay" />

                    <ImageOverlay url={whiteImg} bounds={[[this.state.imageBoundsThird, this.state.imageBoundsSecond], [77.73, 129.02]]}
                                   opacity={opacity} className="imageOverlay" />

                    <ImageOverlay url={whiteImg} bounds={[[this.state.imageBoundsThird, this.state.imageBoundsFourth], [this.state.imageBoundsFirst, 180]]}
                                  opacity={opacity} className="imageOverlay" />                                  

                    <TileLayer className="attribution"
                               attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                               url="https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}{r}.png"
                    />

                    <ImageOverlay url={this.dateToUrl(this.state.currentDate)} alt='Image failed to load'
                                bounds={[[this.state.imageBoundsFirst, this.state.imageBoundsSecond], [this.state.imageBoundsThird, this.state.imageBoundsFourth]]}
                                opacity={opacity} className="imageOverlay" onError={this.handleImageError} onLoad={this.handleImageLoad}
                    /> 

                    {this.state.imageFailedToLoad ? 
                        <ImageOverlay url={whiteImg}
                                    bounds={[[this.state.imageBoundsFirst, this.state.imageBoundsSecond], [this.state.imageBoundsThird, this.state.imageBoundsFourth]]}
                                    opacity={opacity} className="imageOverlay"
                        />
                        :
                        <div></div>
                    }

                    {this.state.popupVisible && <Popup position={this.state.popupLatLng} onClose={this.closePopup} onOpen={this.addPopup} style={{width: '300px'}}>
                        <Tooltip title={'Повеќе информации'} placement={"top"}>
                            <Button className="moreInfoButton" onClick={this.openDetails} onLoad={this.openDetails} >
                            </Button>
                        </Tooltip>
                        <span style={{fontSize: '13px', fontWeight: '500'}}>{this.state.pollutantName}</span>
                        <span style={{fontSize: '13px', fontWeight: '500'}}>&nbsp;&nbsp;&nbsp;
                          {this.state.loadingData && <CircularProgress color="primary" size='15px' className="selectedPollutantLoadingCircle" />}
                          {(this.state.pollutantValues72[this.state.hour + 1] === undefined || 
                           this.state.pollutantValues72[this.state.hour + 1] === null) && !this.state.loadingData ? "No value" : 
                            (!this.state.loadingData && (this.state.pollutantValues72[this.state.hour + 1].val !== null &&
                                                        this.state.pollutantValues72[this.state.hour + 1].val !== undefined &&
                                                        this.state.pollutantValues72[this.state.hour + 1].val !== "NaN" ? 
                                                        this.state.pollutantValues72[this.state.hour + 1].val.toFixed(4) : "No value"
                                                        )
                            )
                          }
                        </span>

                    </Popup>}
                    
                    {this.state.circleVisible &&
                    <CircleMarker className="circlePointer" center={this.state.popupLatLng} radius={5} color="#8B0000" fillColor="#8B0000"
                                  opacity="0.6" fillOpacity="0.6" weight="0" ></CircleMarker>}

                </Map>


                <div className="logosSpan">
                        <img src={pmalarmLogo} alt='No logo found' className="pmAlarmLogoStyle" />
                </div>

                <DropdownPollutant changePollutantFun={this.changePollutant}></DropdownPollutant>
                <DropdownDomain changeDomain={this.changeDomain}></DropdownDomain>
                
                {!this.state.showLocationDetails &&
                <Container fluid className="legendNplayer ">
                    <Row className="rangeSlider">
                        <Col md="12" lg="9">
                            {!this.state.isPlaying &&
                            <button className="btn btn-secondary btnPlayer" onClick={this.player}><i className="fas fa-play"></i></button>}
                            {this.state.isPlaying &&
                            <button className="btn btn-secondary btnPlayer" onClick={this.pausePlayer}><i className="fas fa-pause"></i></button>}
                            <Slider
                                min={0}
                                max={72}
                                marks={marks}
                                ValueLabelComponent={SliderTooltip}
                                value={this.state.hour}
                                onChange={this.handleOnChange}
                                orientation="horizontal"
                                valueLabelDisplay="on"
                            />
                        </Col>
                        <Col  md="12" lg="3">
                             <Legend displayLegend={this.state.pollutantName}></Legend> 
                        </Col>
                    </Row>
                </Container>}

                {this.state.showLocationDetails && <button onClick={this.closeDetails} className="closeBtn"><i className="fa fa-close detailsCloseX"></i></button>}
                {/* {this.state.showLocationDetails && <SelectedLocation location={this.state.selectedLocation} hour={this.state.hour} openErrDivDetails={this.openErrDivDetails}/>} */}
                {this.state.showLocationDetails && <DetailsForPollutantsByLocation style={{position: 'absolute'}} location={this.state.selectedLocation} openErrDivDetails={this.openErrDivDetails}/>}
            </Container>
        );
    }
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(Mapa);
